import React, { useEffect, useState } from 'react';
import './styles.css'; // Import your CSS file

function YearCompletionPercentage() {
  const [percentageCompleted, setPercentageCompleted] = useState('');
  const [daysRemaining, setDaysRemaining] = useState('');

  useEffect(() => {
    calculatePercentageCompleted();
    calculateDaysRemaining();
  }, []);

  const calculatePercentageCompleted = () => {
    const currentDate = new Date();
    const dayOfYear = Math.floor((currentDate - new Date(currentDate.getFullYear(), 0, 0)) / 86400000);
    const totalDays = currentDate.getFullYear() % 4 === 0 ? 366 : 365;
    const calculatedPercentage = ((dayOfYear / totalDays) * 100).toFixed(1);
    setPercentageCompleted(`${calculatedPercentage}%`);
  };

  const calculateDaysRemaining = () => {
    const currentDate = new Date();
    const nextYear = new Date(currentDate.getFullYear() + 1, 0, 1);
    const timeRemaining = nextYear - currentDate;
    const calculatedDaysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    setDaysRemaining(calculatedDaysRemaining);
  };

  return (
    <div>
      <h1>The percentage of the year 2024 completed is:</h1>
      <p id="percentage">{percentageCompleted}</p>

      <h1>Days Remaining to complete 2024 is :</h1>
      <p id="remainingDays">{daysRemaining}</p>
    </div>
  );
}

export default YearCompletionPercentage;
