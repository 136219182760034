import './App.css';

import YearCompletionPercentage from "./YearCompletionPercentage";

function App() {
  return (
    <div className="App">
      <YearCompletionPercentage />
    </div>
  );
}

export default App;
